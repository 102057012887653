import React from "react";
import PropTypes from "prop-types";
import { withApollo } from "react-apollo";
import { getOrderQuery } from "../../api/graphql/getOrder";
import get from "lodash/get";
import deepEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";

class EnrichDestinations extends React.Component {
  latestRequestId = 0;

  constructor(props){
    super(props);
    this.state = {
      destinations: []
    }
  }

  componentDidMount(){
    this.setState({destinations: this.props.destinations});
    this.enrich();
  }

  componentDidUpdate(prevProps){
    if(!deepEqual(prevProps.destinations, this.props.destinations)){
      this.enrich();
    }
  }

  enrich(){
    const thisRequestId = ++this.latestRequestId;
    const { destinations, client } = this.props;
    Promise.all(destinations.map(destination => {
      // Get destination coordinates from the order transactions
      const result = cloneDeep(destination);
      const orderId = result.transactions[0].orderNum;
      const transactionId = result.transactions[0].transactionId;
      const keyPrefix = result.type === "load" ? "pickup" : "unload";
      return new Promise((resolve) => {
        client.query({
          query: getOrderQuery,
          variables: { orderId: `${orderId}` },
          fetchPolicy: "cache-first"
        }).then(response => {
          const order = response.data.getOrder;
          const rows = get(order, "rows", []);
          for(const row of rows) {
            const transaction = row.transactions.find(item => item[`${keyPrefix}TransactionId`] === transactionId);
            if(transaction !== undefined){
              result.coordinatesX = transaction[`${keyPrefix}CoordinatesX`];
              result.coordinatesY = transaction[`${keyPrefix}CoordinatesY`];
              break;
            }
          }
          resolve(result);
        }).catch(() => resolve(result));
      });
    })).then(destinations => {
      // Update destinations only when all have been enriched
      // Race condition prevention
      if(thisRequestId === this.latestRequestId){
        this.setState({destinations: destinations});
      }
    });
  }

  render() {
    const { children } = this.props;
    const { destinations } = this.state;
    return children({
      destinations
    });
  }
}

EnrichDestinations.propTypes = {
  destinations: PropTypes.array.isRequired,
  children: PropTypes.func.isRequired,
};

export default withApollo(EnrichDestinations);
