import React from "react";
import PropTypes from "prop-types";

import styles from "./Spinner.module.scss";

const Spinner = ({ className, color, secondaryColor, small = false }) => {
  return (
    <div className={`${className} ${small ? styles.rootSmall : styles.root}`}>
      <span
        style={{ borderColor: `${color} ${secondaryColor} ${color} ${secondaryColor}` }}
      />
    </div>
  );
};

Spinner.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  secondaryColor: PropTypes.string,
};

Spinner.defaultProps = {
  className: "",
  color: "#2479b3",
  secondaryColor: "transparent"
};

export default Spinner;
