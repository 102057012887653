import PropTypes from 'prop-types';
import React from 'react';

import WaybillPhotoCapture from '../destination/WaybillPhotoCapture';
import styles from './OrderDetails.module.scss';
import OrderTransactions from './OrderTransactions';

const OrderDetails = ({
  allowActions,
  companyName,
  destinationId,
  isOngoingLoad,
  isOngoingUnload,
  isOnline,
  onCopyWaybill,
  onUpdateLoad,
  onUpdateUnload,
  order,
  refreshStyles,
  status,
  transactionIds,
  type,
  fullySigned,
  routeId,
  waybillNumber,
}) => (
  <div className={styles.root}>
    <header>
      <h2>{companyName} tilausnumero {order.orderNum}</h2>

      <div className={styles.orderSummaryGrid}>
        <div className={styles.gridElement}>
          <span className={styles.gridTitle}>As tilausnumero</span>
          <span className={styles.number}>{order.customerOrderNum || '-'}</span>
        </div>
        <div className={styles.gridElement}>
          <span className={styles.gridTitle}>Asiakkaan viite</span>
          <span className={styles.number}>{order.customerRefNum || '-'}</span>
        </div>
        <div className={styles.gridElement}>
          <span className={styles.gridTitle}>Tilausviite</span>
          <span className={styles.number}>{order.orderRefNum || '-'}</span>
        </div>
        <div className={styles.gridElement}>
          <span className={styles.gridTitle}>Toimitustapa</span>
          <span className={styles.number}>{order.delivery || '-'}</span>
        </div>
      </div>
    </header>
    <OrderTransactions
      allowActions={allowActions}
      customerNum={+order.customerNum}
      isOngoingLoad={isOngoingLoad}
      isOngoingUnload={isOngoingUnload}
      isOnline={isOnline}
      onCopyWaybill={onCopyWaybill}
      onUpdateLoad={onUpdateLoad}
      onUpdateUnload={onUpdateUnload}
      orderNum={+order.orderNum} // plus is a sneaky way to convert string to number, duh
      payerNum={+order.payerNum}
      refreshStyles={refreshStyles}
      rows={order.rows}
      transactionIds={transactionIds}
      type={type}
      fullySigned={fullySigned}
    />
    {type === 'unload' && status === 'upcoming' && isOngoingUnload && (
      <WaybillPhotoCapture
        destinationId={destinationId}
        isOnline={isOnline}
        orderNum={+order.orderNum}
        routeId={routeId}
        waybillNumber={waybillNumber}
      />
    )}
  </div>
);

OrderDetails.propTypes = {
  allowActions: PropTypes.bool.isRequired,
  companyName: PropTypes.string.isRequired,
  destinationId: PropTypes.number.isRequired,
  isOngoingLoad: PropTypes.bool.isRequired,
  isOngoingUnload: PropTypes.bool.isRequired,
  isOnline: PropTypes.bool.isRequired,
  onCopyWaybill: PropTypes.func.isRequired,
  onUpdateLoad: PropTypes.func.isRequired,
  onUpdateUnload: PropTypes.func.isRequired,
  order: PropTypes.shape({
    orderNum: PropTypes.string.isRequired,
    customerOrderNum: PropTypes.string.isRequired,
    customerRefNum: PropTypes.string,
    orderRefNum: PropTypes.string,
    delivery: PropTypes.string,
    customerNum: PropTypes.string.isRequired,
    payerNum: PropTypes.string.isRequired,
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        orderedAmount: PropTypes.number.isRequired,
        orderedUnit: PropTypes.string.isRequired,
        commodityName: PropTypes.string.isRequired,
        weight: PropTypes.number,
        items: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
        length: PropTypes.number,
        additionalInfo: PropTypes.array.isRequired,
        transactions: PropTypes.array.isRequired,
      })
    ).isRequired,
  }).isRequired,
  refreshStyles: PropTypes.func.isRequired,
  status: PropTypes.string,
  transactionIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.oneOf(['load', 'unload']).isRequired,
  fullySigned: PropTypes.bool,
  routeId: PropTypes.string,
  waybillNumber: PropTypes.string,
};

export default OrderDetails;
