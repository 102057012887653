import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from "prop-types";
import { Router, Redirect, navigate } from "@reach/router";

import deepEqual from "lodash/isEqual";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";

import updateTransactionInput from "../../utils/updateTransactionInput";

import getDestinationsWithStates from "../../components/routes/utils/getDestinationsWithStates";
import diffDestinations from "../../components/routes/utils/diffDestinations";

import DeviceType from "../../enum/DeviceType";

import Map from "../../components/routes/map/Map";
import Schedule from "../../components/routes/schedule/Schedule";
import LogRouteException from "./LogRouteException";
import LogSecurityException from "./LogSecurityException";
import Order from './Order';
import Destination from './Destination';

const Routes = ({
  destinations,
  refreshRoutes,
  destinationsUpdating,
  deviceType,
  geolocation,
  currentUser,
  currentVehicle,
  refreshStyles,
  mapMode,
  handleSnackbarClose,
  showSnackbar,
  isOnline,
  companyName,
  completeVehicleDestinationsQueryData,
  currentVehicleLicenseNum,
}) => {
  const prevDestinations = useRef([]);
  const destinationsReceived = useRef([...destinations]);
  
  const [schedule, setSchedule] = useState([]);
  const [added, setAdded] = useState([]);
  const [updated, setUpdated] = useState([]);
  const [cancelled, setCancelled] = useState([]);
  
  const [destinationComplete, setDestinationComplete] = useState(false);
  
  const [isLoading, setIsLoading] = useState(false);
  const [invalidTransactions, setInvalidTransactions] = useState(null);
  const [newWaybillNumber, setNewWaybillNumber] = useState(null);

  const setLoadingState = (newLoadingState) => {
    if (newLoadingState !== isLoading) {
      setIsLoading(newLoadingState);
    }
  };

  useEffect(() => {
    const received = [...destinations];
    const prevDest = uniqBy([...destinationsReceived.current, ...prevDestinations.current], "id");

    // Are there changes in the routes? If yes, do get states do get the diff
    const destinationStatesDiff = !deepEqual(destinations, destinationsReceived.current)
      ? diffDestinations(
        destinationsReceived.current,
        destinations,
        isOnline,
        currentVehicleLicenseNum,
        completeVehicleDestinationsQueryData
      )
      : {
        added: [],
        updated: [],
        cancelled: [],
      };

    const schedule = uniqBy([...destinations, ...prevDestinations.current], "id");
    setSchedule(schedule);
    const newUpdated = uniq([...updated, ...destinationStatesDiff.updated]);
    setUpdated(newUpdated);

    // destination can't be both cancelled and added.
    // filter out mutually if either of these are in the new states:
    const newAdded = uniq([...added, ...destinationStatesDiff.added]).filter(
      (id) => !destinationStatesDiff.cancelled.includes(id)
    );
    setAdded(newAdded);

    const destIds = Object.values(destinations).map((dest) => dest.id);
    const newCancelled = uniq([...cancelled, ...destinationStatesDiff.cancelled]).filter(
      (id) => !destinationStatesDiff.added.includes(id) && !destIds.includes(id)
    );
    setCancelled(newCancelled);

    destinationsReceived.current = received;
    prevDestinations.current = prevDest;
  }, [destinations, isOnline, currentVehicleLicenseNum, completeVehicleDestinationsQueryData]);

  const dismissCancelledDestination = (id) => {
    dismissCancelledStates(id);
    const filteredPrevDestinations = [...prevDestinations].filter((destination) => destination.id !== id);
    prevDestinations.current = filteredPrevDestinations;
    const filteredDestinations = [...destinations].filter((destination) => destination.id !== id);
    destinationsReceived.current = filteredDestinations;
  };

  const dismissCancelledStates = (id) => {
    const ids = [...cancelled];
    const newIds = ids.filter((destId) => `${destId}` !== `${id}`);

    if (ids.length !== newIds.length) {
      setCancelled(newIds);
    }
  };

  const dismissAddedStates = (id) => {
    const ids = [...added];
    const newIds = ids.filter((destId) => `${destId}` !== `${id}`);

    if (ids.length !== newIds.length) {
      setAdded(newIds);
    }
  };

  const dismissUpdatedStates = (id) => {
    const ids = [...updated];
    const newIds = ids.filter((destId) => `${destId}` !== `${id}`);

    if (ids.length !== newIds.length) {
      setUpdated(newIds);
    }
  };

  const destinationsWithStates = useMemo(() => getDestinationsWithStates(schedule, {
    added: added,
    updated: updated,
    cancelled: cancelled,
  }), [schedule, added, updated, cancelled]);

  return (
    <Router className="fullsize flexcolumn">
      <Schedule
        path="schedule/:status"
        destinations={destinationsWithStates}
        dismissCancelledDestination={dismissCancelledDestination}
        refreshRoutes={refreshRoutes}
        destinationsUpdating={destinationsUpdating}
        allowExceptions={deviceType === DeviceType.VEHICLE}
        refreshStyles={refreshStyles}
      />
      <Destination
        path="schedule/:status/destination/:destinationId/"
        destinations={destinationsWithStates}
        refreshRoutes={refreshRoutes}
        invalidTransactions={invalidTransactions}
        onInvalidTransactions={(invalidTransactions) => setInvalidTransactions(invalidTransactions)}
        destinationComplete={destinationComplete}
        isLoading={isLoading || destinationsUpdating}
        setLoadingState={setLoadingState}
        allowActions={deviceType === DeviceType.VEHICLE}
        allowExceptions={deviceType === DeviceType.VEHICLE}
        refreshStyles={refreshStyles}
        dismissStates={(id) => {
          dismissAddedStates(id);
          dismissUpdatedStates(id);
        }}
        onClosePopup={() => {
          if (destinationComplete) {
            navigate("/routes/schedule/upcoming");
          }

          setInvalidTransactions(null);
          setDestinationComplete(false);
        }}
        isOnline={isOnline}
        newWaybillNumber={newWaybillNumber}
        currentVehicle={currentVehicle}
        companyName={companyName}
      />
      <Order
        path="schedule/:status/destination/:destinationId/:orderNum"
        destinations={destinationsWithStates}
        isLoading={isLoading || destinationsUpdating}
        setLoadingState={setLoadingState}
        refreshRoutes={refreshRoutes}
        onInvalidTransactions={(invalidTransactions) => setInvalidTransactions(invalidTransactions)}
        allowActions={deviceType === DeviceType.VEHICLE}
        allowExceptions={deviceType === DeviceType.VEHICLE}
        refreshStyles={refreshStyles}
        updateTransactionLoadInput={(pickupTransactionId, values) =>
          updateTransactionInput(pickupTransactionId, values)
        }
        updateTransactionUnloadInput={(unloadTransactionId, values) =>
          updateTransactionInput(unloadTransactionId, values)
        }
        isOnline={isOnline}
        handleWaybillNumberChange={setNewWaybillNumber}
        newWaybillNumber={newWaybillNumber}
        currentVehicle={currentVehicle}
        companyName={companyName}
      />
      {deviceType === DeviceType.VEHICLE && (
        <Map
          path="map/*"
          geolocation={geolocation}
          destinations={destinationsWithStates}
          dismissCancelledDestination={dismissCancelledDestination}
          refreshRoutes={refreshRoutes}
          destinationsUpdating={destinationsUpdating}
          currentVehicle={currentVehicle}
          refreshStyles={refreshStyles}
          mapMode={mapMode}
          onlyTransactions={false}
          handleSnackbarClose={handleSnackbarClose}
          showSnackbar={showSnackbar}
        />
      )}
      <Map
        path="map-only-transactions/:destinationId"
        geolocation={geolocation}
        destinations={destinationsWithStates}
        dismissCancelledDestination={dismissCancelledDestination}
        refreshRoutes={refreshRoutes}
        destinationsUpdating={destinationsUpdating}
        currentVehicle={currentVehicle}
        refreshStyles={refreshStyles}
        mapMode={mapMode}
        onlyTransactions={true}
        deviceType={deviceType}
      />
      {deviceType === DeviceType.VEHICLE &&
        ["exception", "exception/:destinationId"].map((path) => (
          <LogRouteException
            key={path}
            path={path}
            geolocation={geolocation}
            currentUser={currentUser}
            currentVehicle={currentVehicle}
            destinations={destinationsWithStates}
            refreshStyles={refreshStyles}
            isOnline={isOnline}
          />
        ))}
      {deviceType === DeviceType.VEHICLE &&
        ["security", "security/:destinationId"].map((path) => (
          <LogSecurityException
            key={path}
            path={path}
            geolocation={geolocation}
            currentUser={currentUser}
            currentVehicle={currentVehicle}
            destinations={destinationsWithStates}
            refreshStyles={refreshStyles}
            isOnline={isOnline}
          />
        ))}
      <Redirect noThrow from="*" to={`/routes/schedule/upcoming`} />
    </Router>
  );
};

Routes.propTypes = {
  destinations: PropTypes.array.isRequired,
  refreshRoutes: PropTypes.func.isRequired,
  currentLocation: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
};

export default Routes;
