import React from "react";
import PropTypes from "prop-types";
import uniq from "lodash/uniq";
import memoize from "memoize-one";
import deepEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";

import parseCommoditiesFromTransactions from "./utils/parseCommoditiesFromTransactions";
import getRestString from "../../../utils/getRestString";

import styles from "./Transactions.module.scss";

const getOrderNums = transactions =>
  pickUniqueProps(transactions, "customerOrderNum").filter(
    orderNum => orderNum
  );

const pickUniqueProps = memoize(
  (items, propName) =>
    uniq(items.reduce((all, item) => [...all, item[propName]], [])),
  deepEqual
);

const Transactions = ({ transactions }) => {
  const orderNums = getOrderNums(transactions);
  const commodities = sortBy(
    parseCommoditiesFromTransactions(transactions),
    "name"
  );

  return (
    <div className={styles.root}>
      {orderNums.length > 0 && (
        <span className={styles.id}>
          <span title={orderNums[0]}>{orderNums[0]}</span>
          {getRestString(orderNums)}
        </span>
      )}
      <span className={styles.commodity}>{`${
        commodities[0].name
      }, ${commodities[0].amount.toLocaleString("fi-FI")} ${
        commodities[0].unit
      }${getRestString(commodities)}`}</span>
    </div>
  );
};

Transactions.propTypes = {
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      orderNum: PropTypes.string.isRequired,
      commodityName: PropTypes.string.isRequired,
      kilos: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf([null])
      ])
    })
  )
};

export default Transactions;
