import { openDB } from 'idb';

const dbPromise = openDB('rahtari', 1, {
  upgrade(db) {
    db.createObjectStore('waybillImages');
  },
});

export const removeAllImages = async () => {
  const db = await dbPromise;
  return db.clear('waybillImages');
}

const constructKey = (destinationId, orderNum, imageId) => `${destinationId}-${orderNum}-${imageId}`;

export const addImage = async (destinationId, orderNum, imageId, imageBlob) => {
  const db = await dbPromise;
  const tx = db.transaction('waybillImages', 'readwrite');
  const store = tx.objectStore('waybillImages');
  const key = constructKey(destinationId, orderNum, imageId);
  const item = {
    imageData: imageBlob,
    timestamp: Date.now(),
    status: 'loading',
  };
  await store.put(item, key);
  await tx.done;
}

// status can be 'loading', 'done', 'error'
export const updateImageStatus = async (destinationId, orderNum, imageId, status) => {
  const key = constructKey(destinationId, orderNum, imageId);
  const db = await dbPromise;
  const tx = db.transaction('waybillImages', 'readwrite');
  const store = tx.objectStore('waybillImages');
  const object = await store.get(key);
  if (object) {
    object.status = status;
    await store.put(object, key);
    await tx.done;
    return true;
  }
  return false;
};

export const deleteImage = async (destinationId, orderNum, imageId) => {
  const key = constructKey(destinationId, orderNum, imageId);
  const db = await dbPromise;
  try {
    await db.delete('waybillImages', key);
    return true;
  } catch (error) {
    console.error('Error deleting image:', error);
    return false;
  }
};

export const getImage = async (destinationId, orderNum, imageId) => {
  const key = constructKey(destinationId, orderNum, imageId);
  const db = await dbPromise;
  const object = await db.get('waybillImages', key);
  return object;
};

export const hasImages = async (destinationId, orderNum) => {
  const db = await dbPromise;
  for (let i = 1; i < 7; i++) {
    const key = constructKey(destinationId, orderNum, i);
    const object = await db.get('waybillImages', key);
    if (object) {
      return true;
    }
  }
};
