import React from "react";
import PropTypes from "prop-types";

import getUniqueProps from "../../../utils/getUniqueProps";

import DestinationDetails from "./DestinationDetails";
import DestinationOrdersSummary from "./DestinationOrdersSummary";

const Destination = ({
  destination,
  currentVehicle,
  companyName,
}) => {
  const transactions = destination.transactions;
  const orderNums = getUniqueProps(transactions, "orderNum");
  const commodities = getUniqueProps(transactions, "commodityName");

  return (
    <>
      <DestinationDetails destination={destination} />
      <DestinationOrdersSummary
        numOrders={orderNums.length}
        numRows={transactions.length}
        commodities={commodities}
        destination={destination}
        currentVehicle={currentVehicle}
        companyName={companyName}
      />
    </>
  );
};

Destination.propTypes = {
  destination: PropTypes.shape({
    type: PropTypes.oneOf(["load", "unload"]).isRequired,
    name: PropTypes.string,
    city: PropTypes.string.isRequired,
    eta: PropTypes.string.isRequired,
    etd: PropTypes.string,
    transactions: PropTypes.array.isRequired,
    additionalInfo: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array
    ])
  }),
  currentVehicle: PropTypes.object,
  companyName: PropTypes.string.isRequired
};

Destination.defaultPropTypes = {
  additionalInfo: []
};

export default Destination;
