import React, { useEffect } from 'react';
import PropTypes from "prop-types";

import DestinationComponent from "../../components/routes/destination/Destination";
import Finished from "../../components/routes/destination/Finished";

import DestinationWrapper from './DestinationWrapper';
import InvalidLoad from "./InvalidLoad";

import { getDestination, getWaybillDataFromDestination } from './utils';
import Loading from '../../components/layout/Loading';

// Find the correct destination to show and render the Destination component including all the relevant orders
const Destination = ({
  destinations,
  destinationId,
  dismissStates,
  invalidTransactions,
  destinationComplete,
  isLoading,
  setLoadingState,
  onClosePopup,
  allowActions,
  allowExceptions,
  refreshRoutes,
  onInvalidTransactions,
  refreshStyles,
  isOnline,
  newWaybillNumber,
  currentVehicle,
  companyName,
}) => {
  const destination = getDestination(destinations, destinationId);

  useEffect(() => {
    dismissStates(destinationId);
  }, []);

  if (!destination) {
    return <Loading />;
  }

  // Transactions do not contain the routing order number in them
  // We need them for the schedule view
  // Destinations do however have the routing order
  // Let's enrich transactions with the routing order by pulling it from the destinations
  // for properly sorting the unload transactions later in the schedule view
  destination.transactions = destination.transactions.map((item) => {
    let order = null;

    // Go through the destinations array and each destination transaction for same orderNum - and on hit take the destination order
    destinations.forEach((d) => {
      d.transactions.forEach((t) => {
        if (t.orderNum === item.orderNum) {
          order = d.order;
        }
      });
    });

    // Return the original item enriched with the order
    return {
      ...item,
      order,
    };
  });

  const { simpleWaybill } = getWaybillDataFromDestination(null, destination);
  const waybillData = {
    ...simpleWaybill,
    transactions: destination.transactions,
    isOnline,
    allowActions,
  };

  return (
    <>
      {invalidTransactions && invalidTransactions.length > 0 && (
        <InvalidLoad
          destination={destination}
          invalidTransactions={invalidTransactions}
          onClose={onClosePopup}
          companyName={companyName}
        />
      )}
      {destinationComplete && (
        <Finished
          type={destination.type}
          eta={destination.eta}
          etd={destination.etd}
          ata={destination.ata}
          atd={destination.atd}
          onClose={onClosePopup}
        />
      )}
      {destination && (
        <DestinationWrapper
          headerLinkTarget={"../.."}
          headerLinkText="Takaisin ajoihin"
          destination={destination}
          isLoading={isLoading}
          setLoadingState={setLoadingState}
          allowActions={allowActions}
          allowExceptions={allowExceptions}
          orderView={false}
          refreshRoutes={refreshRoutes}
          onInvalidTransactions={onInvalidTransactions}
          refreshStyles={refreshStyles}
          isOnline={isOnline}
          newWaybillNumber={newWaybillNumber}
          waybillData={waybillData}
        >
          {() => (
            <DestinationComponent
            destination={destination}
            currentVehicle={currentVehicle}
            companyName={companyName}
          />
          )}
        </DestinationWrapper>
      )}
    </>
  );
};

Destination.propTypes = {
  destinations: PropTypes.array.isRequired,
  destinationId: PropTypes.number,
  dismissStates: PropTypes.func.isRequired,
  invalidTransactions: PropTypes.array,
  destinationComplete: PropTypes.bool,
  isLoading: PropTypes.bool,
  setLoadingState: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  allowActions: PropTypes.bool.isRequired,
  allowExceptions: PropTypes.bool.isRequired,
  refreshRoutes: PropTypes.func.isRequired,
  onInvalidTransactions: PropTypes.func.isRequired,
  refreshStyles: PropTypes.func.isRequired,
  isOnline: PropTypes.bool.isRequired,
  newWaybillNumber: PropTypes.string,
  currentVehicle: PropTypes.object,
  companyName: PropTypes.string.isRequired,
};

export default Destination;
