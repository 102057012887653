import React from "react";
import PropTypes from "prop-types";
import authAPI from "../api/authAPI";
import { Router, navigate, Redirect } from "@reach/router";

import CenteredContent from "../components/layout/CenteredContent";
import Loading from "../components/layout/Loading";
import Login from "../components/auth/Login";
import AdminLogin from "../components/auth/AdminLogin";
import ForgotPassword from "../components/auth/ForgotPassword";
import UpdatePassword from "../components/auth/UpdatePassword";

class Authentication extends React.Component {
  constructor(props) {
    super(props);

    // Federated authentication will pass back a 'code' param in the query string
    const queryString = window.location.search.replace(/^\?/, '');
    let query = {};
    queryString.split("&")
      .map(q => q.split("=") )
      .map( param => query[param[0]] = param[1] );

    this.state = {
      fedAuthRedirect: (query['code']) ? true : false,
      loading: true
    };
  }

  componentDidMount() {
    // First check if there is a session/user
    this.updateSession();

    // Schedule a few rechecks for a session/user as the Azure AD redirect has a little bit of lag
    if (this.state.fedAuthRedirect) {
      let maxItr = 5;
      let currentItr = 0;
      let checkInterval = setInterval( () => {
        // Only try a set number of times
        if (currentItr > maxItr) {
          clearInterval(checkInterval);
        } else {
          currentItr++;
        }
        // Check if user  
        if (this.state.session) {
          clearInterval(checkInterval);
        } else {
          this.updateSession();
        }
      }, 1000);
    }
  }

  async updateSession() {
    const session = await authAPI.getCurrentSession();
    const user = await authAPI.getCurrentUser();

    if (!session && user && user.challengeName === "NEW_PASSWORD_REQUIRED") {
      // password change required!
      navigate("/login/update");
    }

    this.setState({
      loading: false,
      session,
      user
    });
  }

  onAuth = () => {
    this.updateSession();
    navigate("/");
  };

  render() {
    const { newOrgGoLiveTimestamp } = this.props;
    const { loading, session, user, fedAuthRedirect} = this.state;
    const isAuthenticated = !!session && !!user;

    // user session is being loaded
    if (loading || (fedAuthRedirect&&!isAuthenticated) ) {
      return <Loading />;
    }

    // user is not authenticated, render the athentication content
    if (!isAuthenticated) {
      // Clear cache if not authenticated
      localStorage.clear();
      this.props.clearCache && this.props.clearCache();
      return (
        <CenteredContent>
          <Router>
            <Login newOrgGoLiveTimestamp={newOrgGoLiveTimestamp} path="login" onAuth={this.onAuth} />
            <AdminLogin newOrgGoLiveTimestamp={newOrgGoLiveTimestamp} path="admin" />
            <ForgotPassword newOrgGoLiveTimestamp={newOrgGoLiveTimestamp} path="login/forgot" onAuth={this.onAuth} />
            <UpdatePassword newOrgGoLiveTimestamp={newOrgGoLiveTimestamp} path="login/update" onAuth={this.onAuth} />
            <Redirect noThrow from="*" to="/login" />
          </Router>
        </CenteredContent>
      );
    }

    // user authenticated so call the render prop with the current user 
    return this.props.render(async () => await authAPI.getCurrentUser());
  }
}

Authentication.propTypes = {
  render: PropTypes.func.isRequired,
  clearCache: PropTypes.func,
};

export default Authentication;
