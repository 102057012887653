import deepEqual from "lodash/isEqual";
import memoize from "memoize-one";

import getUniqueProps from "../../utils/getUniqueProps";
import { getWaybillTransactionData } from '../../components/routes/utils/waybills';

// get single destination from routes
export const getDestination = memoize((destinations, destinationId) => {
  return destinations.find((destination) => `${destination.id}` === `${destinationId}`);
}, deepEqual);

export const getWaybillDataFromDestination = (orderData, destination) => {
  const isOngoingLoad = destination.type === "load" && !!destination.ata && !destination.atd;
  const isOngoingUnload = !isOngoingLoad && destination.type === "unload" && !!destination.ata && !destination.atd;
  const isIncompleteUnload = destination.type === "unload" && !destination.atd;

  const destinationTransactions = destination.transactions;
  const destinationTransactionIds = getUniqueProps(destinationTransactions, "transactionId");

  const order = orderData !== null ? getWaybillTransactionData(orderData, isOngoingLoad, isOngoingUnload, isIncompleteUnload, destinationTransactionIds) : undefined;

  const transactions = [];
  const rows = order?.rows || [];
  rows &&
    rows.forEach((row) =>
      row.transactions.forEach((transaction) => {
        if (
          destinationTransactionIds.includes(transaction.pickupTransactionId) ||
          destinationTransactionIds.includes(transaction.unloadTransactionId)
        ) {
          transactions.push({ ...transaction, orderNum: order?.orderNum });
        }
      })
    );
  return {
    simpleWaybill: {
      additionalInfo: order?.additionalInfo,
      contractorData: order?.contractorData,
      customerData: order?.customerData,
      customerName: order?.customerName,
      customerNum: +order?.customerNum,
      customerOrderNum: order?.customerOrderNum,
      customerRefNum: order?.customerRefNum,
      delivery: order?.delivery,
      deliveryCondition: order?.deliveryCondition,
      id: order?.id,
      isOngoingLoad,
      isOngoingUnload,
      orderDate: order?.orderDate,
      orderId: order?.orderNum,
      orderRefNum: order?.orderRefNum,
      payerName: order?.payerName,
      payerNum: +order?.payerNum,
      routeId: destination.routeId,
      rows,
      transactionIds: destinationTransactionIds,
      transactions,
      type: destination.type,
    },
    order,
  };
};
