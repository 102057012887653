import React, { useState } from 'react';
import PropTypes from "prop-types";
import { navigate } from "@reach/router";
import Query from "../../apollo/extensions/CachedQuery";

import * as Sentry from "@sentry/browser";

import { getOrderQuery } from "../../api/graphql/getOrder";

import Loading from "../../components/layout/Loading";
import Error from "../../components/layout/Error";
import OrderDetails from "../../components/routes/order/OrderDetails";

import DestinationWrapper from './DestinationWrapper';
import { getDestination, getWaybillDataFromDestination } from './utils';

const Order = ({
  destinations,
  destinationId,
  isLoading,
  setLoadingState,
  orderNum,
  updateTransactionLoadInput,
  updateTransactionUnloadInput,
  allowActions,
  allowExceptions,
  refreshRoutes,
  onInvalidTransactions,
  refreshStyles,
  isOnline,
  handleWaybillNumberChange,
  newWaybillNumber,
  currentVehicle,
  companyName,
}) => {
  const destination = getDestination(destinations, destinationId);
  const [fullySigned, setFullySigned] = useState(false);

  if (!destination) {
    return <Loading />;
  }

  return (
    <Query query={getOrderQuery} variables={{ orderId: `${orderNum}` }} fetchPolicy="cache-and-network">
      {({ loading, cached, error, data }) => {
        if (loading && !cached) return <Loading />;
        if (error) {
          Sentry.captureEvent({
            message: "Query error - Routes",
            extra: {
              error: error,
              data: data,
              variableOrderId: orderNum,
            },
          });
        }
        if (error) return <Error error={error} />;

        let orderData = data.getOrder

        // if there's no valid order for the given id, redirect to parent
        if (!orderData) {
          console.log("No order data found for orderNum", orderNum);
          navigate("/routes");
          return null;
        }

        const { simpleWaybill, order } = getWaybillDataFromDestination(orderData, destination);

        const waybillData = {
          ...simpleWaybill,
          isOnline,
          allowActions,
        };

        return (
          <DestinationWrapper
            headerLinkTarget="../"
            headerLinkText="Takaisin käyntipaikkaan"
            destination={destination}
            isLoading={isLoading}
            setLoadingState={setLoadingState}
            className={destination.status}
            allowActions={allowActions}
            allowExceptions={allowExceptions}
            orderView={true}
            refreshRoutes={refreshRoutes}
            onInvalidTransactions={onInvalidTransactions}
            waybillData={waybillData}
            isOnline={isOnline}
            refreshStyles={refreshStyles}
            newWaybillNumber={newWaybillNumber}
            currentVehicle={currentVehicle}
            setSigned={setFullySigned}
          >
            {(waybillNumber) => (
              <OrderDetails
                allowActions={allowActions}
                companyName={companyName}
                destinationId={+destinationId}
                isOngoingLoad={waybillData.isOngoingLoad}
                isOngoingUnload={waybillData.isOngoingUnload}
                isOnline={isOnline}
                onCopyWaybill={(pickupTransactionId, waybill) => {
                  handleWaybillNumberChange(waybill);
                  return updateTransactionLoadInput(pickupTransactionId, { waybill: waybill })
                }}
                onUpdateLoad={(pickupTransactionId, values) => {
                  handleWaybillNumberChange(values.waybill);
                  updateTransactionLoadInput(pickupTransactionId, values);
                }}
                onUpdateUnload={(unloadTransactionId, values) =>
                  updateTransactionUnloadInput(unloadTransactionId, values)
                }
                order={order}
                refreshStyles={refreshStyles}
                status={destination.status}
                transactionIds={waybillData.transactionIds}
                type={destination.type}
                fullySigned={fullySigned}
                routeId={destination.routeId}
                waybillNumber={waybillNumber}
              />
            )}
          </DestinationWrapper>
        );
      }}
    </Query>
  );
};

Order.propTypes = {
  destinations: PropTypes.array.isRequired,
  destinationId: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  setLoadingState: PropTypes.func.isRequired,
  orderNum: PropTypes.string,
  updateTransactionLoadInput: PropTypes.func.isRequired,
  updateTransactionUnloadInput: PropTypes.func.isRequired,
  allowActions: PropTypes.bool.isRequired,
  allowExceptions: PropTypes.bool.isRequired,
  refreshRoutes: PropTypes.func.isRequired,
  onInvalidTransactions: PropTypes.func.isRequired,
  refreshStyles: PropTypes.func.isRequired,
  isOnline: PropTypes.bool.isRequired,
  handleWaybillNumberChange: PropTypes.func.isRequired,
  newWaybillNumber: PropTypes.string,
  currentVehicle: PropTypes.object,
  companyName: PropTypes.string,
};

export default Order;
