import React from 'react';
import "./WaybillPhotoCapture.css";

import WaybillPhotoUpload from './WaybillPhotoUpload';

function WaybillPhotoCapture(props) {
  const {
    destinationId,
    orderNum,
    routeId,
    waybillNumber,
  } = props;

  return (
    <div className="waybillImages">
      <h2>Kuljetusasiakirjakuvat</h2>
      <div className="imageFrameContainer">
        {[...Array(6)].map((_, index) => (
          <WaybillPhotoUpload
            key={index}
            destinationId={destinationId}
            orderNum={orderNum}
            routeId={routeId}
            waybillNumber={waybillNumber}
            imageIndex={index + 1}
          />
        ))}
      </div>
    </div>
  );
}

export default WaybillPhotoCapture;
