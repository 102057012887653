import React, { useEffect, useState } from 'react';
import { withApollo } from "react-apollo";
import { styled } from '@mui/material/styles';
import { Button, IconButton } from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import "./WaybillPhotoCapture.css";

import { addImage, deleteImage, getImage, updateImageStatus } from '../../../utils/db';
import { generateUploadUrlQuery } from '../../../api/graphql/generateUploadUrl';
import { deleteWaybillImageMutation } from '../../../api/graphql/deleteWaybillImage';
import { uploadWaybillImageToS3 } from '../../../api/api';
import Spinner from '../../layout/Spinner';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const getPresignedUploadUrl = async (appSyncClient, routeId, orderId, waybillNum, imageId) => {
  try {
    const response = await appSyncClient.query({
      query: generateUploadUrlQuery,
      variables: {
        routeId,
        orderId,
        waybillNum,
        imageId,
      },
      fetchPolicy: 'network-only'
    });
    return response.data.generateUploadUrl;
  } catch (error) {
    console.log('Query error on generateUploadUrl', JSON.stringify(error, null, 2));
  }
}

const deleteWaybillImage = async (appSyncClient, routeId, orderId, waybillNum, imageId) => {
  console.log('deleting image', routeId, orderId, waybillNum, imageId);
  try {
    const response = await appSyncClient.mutate({
      mutation: deleteWaybillImageMutation,
      variables: {
        routeId,
        orderId,
        waybillNum,
        imageId,
      },
    });
    return response.data.deleteWaybillImage;
  } catch (error) {
    console.log('Mutation error on deleteWaybillImage', JSON.stringify(error, null, 2));
  }
}

const getImageStatusIcon = (status) => {
  switch (status) {
    case 'loading':
      return <Spinner small={true} />;
    case 'done':
      return <CloudDoneIcon sx={{ color: "#2479b3" }} />;
    case 'error':
      return <CloudOffIcon sx={{ color: "#2479b3" }} />;
    default:
      return null;
  }
};

const getSrcFromFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
}

function WaybillPhotoUpload(props) {
  const {
    client,
    destinationId,
    orderNum,
    routeId,
    waybillNumber,
    imageIndex,
  } = props;

  const [imgSrc, setImgSrc] = useState(null);
  const [imageStatus, setImageStatus] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchImages = async () => {
      setLoading(true);
      const storedImage = await getImage(destinationId, orderNum, imageIndex);
      if (storedImage) {
        setImgSrc(storedImage.imageData);
        setImageStatus(storedImage.status);
      }
      setLoading(false);
    };

    if (waybillNumber && orderNum !== null && !loading && !imgSrc) {
      fetchImages();
    }
  }, [waybillNumber, orderNum]);

  const handleImgChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      console.log('No file selected');
      return;
    }
    const imgsrc = await getSrcFromFile(file);
    setImgSrc(imgsrc);

    try {
      await addImage(destinationId, orderNum, imageIndex, imgsrc);
      setImageStatus('loading');

      const uploadUrl = await getPresignedUploadUrl(client, parseInt(routeId, 10), orderNum, waybillNumber, imageIndex);
      const res = await uploadWaybillImageToS3(uploadUrl.url, file);
      console.log('uploadWaybillImageToS3', res);
      if (res.ok) {
        await updateImageStatus(destinationId, orderNum, imageIndex, 'done');
        setImageStatus('done');
      } else {
        console.error('Error uploading waybill image:', res);
        await updateImageStatus(destinationId, orderNum, imageIndex, 'error');
        setImageStatus('error');
      }
    } catch (error) {
      console.error('Error uploading waybill image:', error);
      await updateImageStatus(destinationId, orderNum, imageIndex, 'error');
      setImageStatus('error');
    }
  }

  const handleImgDelete = async () => {
    setImageStatus('loading');
    try {
      const deleteFromS3 = await deleteWaybillImage(client, routeId, orderNum, waybillNumber, imageIndex);
      const deleteFromDb = await deleteImage(destinationId, orderNum, imageIndex);
      console.log('deleteFromS3', deleteFromS3, 'deleteFromDb', deleteFromDb);
      if (deleteFromDb && deleteFromS3) {
        setImgSrc(null);
      }
    } catch (error) {
      console.error('Error deleting waybill image:', error);
    }
  }

  return (
    <div key={imageIndex} className="container">
      <h3 className="imageHeader">Kuva {imageIndex}</h3>
      {!imgSrc ? (
        <Button
          component="label"
          variant="outlined"
          tabIndex={-1}
          startIcon={<AddAPhotoIcon sx={{ width: "60px", height: "60px"}}/>}
          sx={{
            width: "300px",
            height: "360px",
            color: "rgba(0,0,0,.6)",
            border: "2px solid rgba(0,0,0,.3)",
            borderRadius: "8px",
          }}
        >
          <VisuallyHiddenInput
            type="file"
            onChange={(event) => handleImgChange(event)}
          />
        </Button>
      ) : (
        <div className="imageContainer">
          <img
            src={imgSrc}
            height={"auto"}
            width={"auto"}
            style={{ maxHeight: "360px", maxWidth: "300px", verticalAlign: "middle", display: "block" }}
            alt={`image${imageIndex}`}
            />
        </div>
      )}
      <div className={`statusContainer${imgSrc ? '' : 'Hidden'}`}>
        <div>
          <Button
            component="label"
            startIcon={<AddAPhotoIcon />}
            sx={{
              color: "rgba(0,0,0,.6)",
            }}
          >
            <VisuallyHiddenInput
              type="file"
              onChange={(event) => handleImgChange(event)}
              onClick={(event) => {event.target.value = null}}
            />
          </Button>
          <IconButton
            onClick={() => handleImgDelete()}>
            <DeleteIcon />
          </IconButton>
        </div>
        {getImageStatusIcon(imageStatus)}
      </div>
    </div>
  );
}

export default withApollo(WaybillPhotoUpload);
